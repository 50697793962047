import { lazy } from "react";
import Loadable from "ui-component/Loadable";

// Layout
import MainLayoutMobile from "layout/MainLayoutMobile";
// import AccessLayoutMain from "layout/AccessLayoutMobile";

// Pages
const Dashboard = Loadable(lazy(() => import("views/auth/mobileAuth/dashboard")));
const ProductDetail = Loadable(lazy(() => import("views/auth/mobileAuth/productDetail")));
const Marketplace = Loadable(lazy(() => import("views/auth/mobileAuth/marketplace")));
const Bags = Loadable(lazy(() => import("views/auth/mobileAuth/bags")));
const UserProfile = Loadable(lazy(() => import("views/auth/mobileAuth/userProfile")));
const UserProfileEdit = Loadable(lazy(() => import("views/auth/mobileAuth/userProfileEdit")));
const OrderSummary = Loadable(lazy(() => import("views/auth/mobileAuth/orderSummary")));
const MyOrders = Loadable(lazy(() => import("views/auth/mobileAuth/myOrders")));
const OrderDetail = Loadable(lazy(() => import("views/auth/mobileAuth/myOrders/orderDetails")));
const AddNewAddress = Loadable(lazy(() => import("views/auth/mobileAuth/addNewAddress/AddAddress")));
const YourAddress = Loadable(lazy(() => import("views/auth/mobileAuth/yourAddress/Addresses")));
// const ChangePassword = Loadable(lazy(() => import("views/auth/mobileAuth/changePassword")));

const MobileRoutes = {
  path: "/",
  element: <MainLayoutMobile />,
  children: [
    {
      path: "/*",
      element: <Dashboard />
    },
    {
      path: "/",
      element: <Dashboard />
    },
    {
      path: "/home",
      element: <Dashboard />
    },

    {
      path: "/creatorProfile",
      element: <UserProfile />
    },
    {
      path: "/creatorProfile-edit",
      element: <UserProfileEdit />
    },

    {
      path: "/add-address",
      element: <AddNewAddress />
    },
    {
      path: "/edit-address/:id",
      element: <AddNewAddress />
    },
    {
      path: "/addresses",
      element: <YourAddress />
    },
    {
      path: "/myactivity",
      element: <MyOrders />
    },
    {
      path: "/productDetails/:id",
      element: <ProductDetail />
    },
    {
      path: "/mobile-order/:id",
      element: <OrderSummary />
    },
    {
      path: "/myactivitydetailmint/:orderId",
      element: <OrderDetail />
    }
    // {
    //   path: "/mobile-change-password",
    //   element: <ChangePassword />
    // }
  ]
};

const developmentRoutes = [
  {
    path: "/marketplace",
    element: <Marketplace />
  },
  {
    path: "/mobile-bags",
    element: <Bags />
  }
];

const productionRoutes = [];
const routesToAdd = process.env.REACT_APP_MOBILE_ENV === "0" ? developmentRoutes : productionRoutes;
MobileRoutes.children = MobileRoutes.children.concat(routesToAdd);

export default MobileRoutes;
