import { all, put } from "redux-saga/effects";
import { SNACKBAR_OPEN } from "store/actions";
import { takeLatest } from "redux-saga/effects";

// there are four types of alertSeverity (info,success,error,warning)

export function* setNotification(alertSeverity, message) {
  yield put({
    type: SNACKBAR_OPEN,
    open: true,
    message: message,
    variant: "alert",
    alertSeverity: alertSeverity,
    transition: "SlideRight",
    anchorOrigin: { vertical: "top", horizontal: "right" }
  });
}

export function* setNotificationMobile(action) {
  const { alertSeverity, message, title } = action;
  yield put({
    type: SNACKBAR_OPEN,
    open: true,
    title,
    message,
    variant: "alert",
    alertSeverity,
    transition: "SlideRight",
    anchorOrigin: { vertical: "top", horizontal: "right" }
  });
}

function* watchNotification() {
  yield takeLatest("SHOW_NOTIFICATION", setNotificationMobile);
}

export default function* snackbarSaga() {
  yield all([watchNotification()]);
}
