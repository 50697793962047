import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, IconButton, BottomNavigation, BottomNavigationAction } from "@mui/material";

// Icons
import HomeIcon from "shared/Icons/mobile-icons/HomeIcon";
import ShopIcon from "shared/Icons/mobile-icons/ShopIcon";
import PersonIcon from "shared/Icons/mobile-icons/PersonIcon";
// import CartIcon from "shared/Icons/mobile-icons/CartIcon";
// import NotificationsIcon from "shared/Icons/mobile-icons/NotificationsIcon";

const MainFooter = () => {
  const [value, setValue] = useState("/productDetails/:id");

  // Use Location
  const { pathname } = useLocation();

  // Use Navigation
  const navigate = useNavigate();

  // Handle Navigation
  const handleNavigation = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  useEffect(() => {
    if (window.location.pathname === "/") {
      setValue("/home");
    } else {
      setValue(window.location.pathname);
    }
  }, []);

  const excludedPaths = [
    "/addresses",
    "/add-address",
    "/edit-address",
    "/creatorProfile-edit",
    "/myactivity",
    "/mobile-order"
  ];

  const isPathExcluded = excludedPaths.some((path) => pathname.startsWith(path));
  return (
    <>
      {!isPathExcluded && (
        <footer className="main-footer">
          {pathname.startsWith("/productDetails/") ? null : (
            <Box className="main-footer-inner">
              {/* Swap Circle */}
              <Box className="swap-circle" />
              {/* Swap Circle End */}

              {/* Swap Button */}
              <Box className="swap-btn-wrap">
                <Box className="swap-btn-wrap-inner">
                  <IconButton className="swap-btn">
                    <ShopIcon />
                  </IconButton>
                </Box>
              </Box>
              {/* Swap Button End */}

              {/* Bottom Navigation */}
              <BottomNavigation value={value} onChange={handleNavigation} sx={{ justifyContent: "space-between" }}>
                <BottomNavigationAction value="/home" icon={<HomeIcon />} />
                <BottomNavigationAction value="/creatorProfile" icon={<PersonIcon />} />
                {/* <BottomNavigationAction value="/marketplace" icon={<CartIcon />} /> */}
                {/* <BottomNavigationAction 
              value="/mobile-login" 
              icon={
                <Badge badgeContent={4} color="primary">
                <NotificationsIcon />
                </Badge>
                }
                /> */}
              </BottomNavigation>
              {/* Bottom Navigation End */}
            </Box>
          )}
        </footer>
      )}
    </>
  );
};

export default MainFooter;
