import React, { useEffect } from "react";
import { Stack } from "@mui/system";
import { Icons } from "shared/Icons/Icons";
import { Box, Modal, Typography, Slide } from "@mui/material";

const MobileSnackbar = ({ open, handleClose, title, message }) => {
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        handleClose();
      }, 6000);
      return () => clearTimeout(timer);
    }
  }, [open, handleClose]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-bottom"
      closeAfterTransition
    >
      <Slide in={open} direction="up" timeout={{ enter: 300, exit: 300 }}>
        <Box className="modal-bottom-content" sx={{ outline: "none" }}>
          <nav aria-label="main mailbox folders">
            <Stack sx={{ flexDirection: "row", gap: "10px" }}>
              <div>{title === "Success" ? Icons.mobileSnackbarIcon : Icons.mobileSnackbarErrorIcon}</div>
              <div>
                <Typography sx={{ color: "white", fontWeight: 500, fontSize: "18px" }}>{title}</Typography>
                <Typography sx={{ color: "white", fontSize: "14px", mt: "6px" }}>{message}</Typography>
              </div>
            </Stack>
          </nav>
        </Box>
      </Slide>
    </Modal>
  );
};

export default MobileSnackbar;
