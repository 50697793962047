import React from "react";
import { Outlet } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

// Components
import MainHeader from "ui-component/mobile-component/MainHeader";
import MainFooter from "ui-component/mobile-component/MainFooter";

// Icons
import UnionIcon from "shared/Icons/mobile-icons/UnionIcon";
import { useSelector } from "react-redux";
import ThirdWebConnectButton from "views/auth/login/component/thirdWebConnectButton";

const MainLayoutMobile = () => {
  // Use Theme
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  return (
    <Box className="wrapper">
      <Box sx={{ opacity: 0, width: 0, height: 0 }}>
        <ThirdWebConnectButton />
      </Box>
      {/* BG Icon */}
      <UnionIcon extraClass="union-icon" />
      {/* BG Icon End */}

      {/* Main Header */}
      <MainHeader />
      {/* Main Header End */}

      {/* Main Content */}
      <main className="main-content">
        <Outlet context={theme} />
      </main>
      {/* Main Content End */}
      {/* Main Footer */}
      {user && <MainFooter />}
      {/* Main Footer End */}
    </Box>
  );
};

export default MainLayoutMobile;
