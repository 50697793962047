import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
// import { GoogleLogin } from "@react-oauth/google";
import { Box, Button, FormControl, InputLabel, FormControlLabel, Checkbox, FormHelperText } from "@mui/material";
import TextField from "@material-ui/core/TextField";
import * as Yup from "yup";

// Components
import AnimateButton from "ui-component/extended/AnimateButton";
import { useDispatch } from "react-redux";
import { SNACKBAR_OPEN } from "store/actions";
import { loginSuccess, setWallet, signupsocial } from "redux/auth/actions";
import axios from "axios";
import { createGoogleAnalyticsForSignup } from "utils/googleAnalytics";
import { CHAIN_IDS, NETWORKS_INFO, SIGNUP_METHODS } from "utils/constants";
import { ethers, utils } from "ethers";

const SocialSignUpForm = ({ theme, referalCode }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [walletAddress, setWalletAddress] = useState("");

  const promptSwitchNetwork = () => {
    window.ethereum
      .request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: NETWORKS_INFO.chainId }]
      })
      .then(() => {
        setTimeout(() => {
          handleConnect();
        }, 1000);
      })
      .catch((error) => {
        if (error.code === 4902) {
          // Chain not added error code
          window.ethereum
            .request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: NETWORKS_INFO.chainId,
                  chainName: NETWORKS_INFO.chainName,
                  nativeCurrency: {
                    name: "MATIC",
                    symbol: "MATIC",
                    decimals: 18
                  },
                  rpcUrls: [NETWORKS_INFO.rpcUrls],
                  blockExplorerUrls: [NETWORKS_INFO.blockExplorerUrl]
                }
              ]
            })
            .then(() => {
              console.log("Added and switched to Polygon mainnet");
              setTimeout(() => {
                handleConnect();
              }, 1000);
            })
            .catch((addError) => {
              console.error("Failed to add and switch to Polygon mainnet:", addError.message);
            });
        } else {
          console.error("Failed to switch to Polygon mainnet:", error.message);
        }
      });
  };

  const handleConnect = async () => {
    if (!window.ethereum) {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: "You need to install metamask to proceed.",
        variant: "alert",
        alertSeverity: "info"
      });

      return;
    } else if (window?.ethereum?.networkVersion !== CHAIN_IDS.POLYGON_CHAIN_ID) {
      promptSwitchNetwork();

      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: `Please switch to ${NETWORKS_INFO.chainName} network from your metamask`,
        variant: "alert",
        alertSeverity: "info"
      });
      setWalletAddress();
      dispatch(setWallet(null));
    } else {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const accounts = await provider.listAccounts();

      if (accounts?.length === 0) {
        dispatch({
          type: "SHOW_NOTIFICATION",
          alertSeverity: "error",
          message: "Please add an account"
        });
        return;
      }

      try {
        const response = await window?.ethereum?.request({ method: "eth_requestAccounts" });
        if (response) {
          const addressed = utils?.getAddress(response[0]);
          setWalletAddress(addressed);
          dispatch(setWallet(addressed));
        }
      } catch (e) {
        if (e.code === -32002) {
          dispatch({
            type: "SHOW_NOTIFICATION",
            alertSeverity: "error",
            message: "Please accept request in your metamask"
          });
        }
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: location.state?.social?.user?.firstName,
        lastName: location.state?.social?.user?.lastName,
        email: location.state?.social?.user?.email,
        refCode: referalCode ? referalCode : "",
        walletAddress: "",
        isEuropeanResident: false,
        termsAndConditions: true,
        emailOptIn: true
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required("First Name is required!").max(42, "First Name can not exceed 42 characters"),
        lastName: Yup.string().required("Last Name is required!").max(42, "Last Name can not exceed 42 characters"),
        // email: Yup.string().email('Enter valid email').max(255).required('Email is required!'),
        // isEuropeanResident: Yup.boolean().oneOf([true])
        termsAndConditions: Yup.boolean().oneOf([true])
        // isWalletSecure: Yup.boolean().oneOf([true])
      })}
      onSubmit={async (values) => {
        if (!location.state?.token) {
          if (walletAddress === "") {
            dispatch({
              type: SNACKBAR_OPEN,
              open: true,
              message: "Please connect to your wallet",
              variant: "alert",
              alertSeverity: "info"
            });
            return;
          }
          dispatch(
            signupsocial({
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              isResidentInEurope: values.isEuropeanResident,
              termsAndConditions: values.termsAndConditions,
              referalCode: values.refCode,
              emailOptIn: values.emailOptIn,
              walletAddress: walletAddress,
              navigate: navigate
            })
          );
        }
        if (location.state?.token) {
          axios
            .post(
              process.env.REACT_APP_API_URL + "auth/magic/login",
              {
                firstName: values?.firstName,
                lastName: values?.lastName,
                isResidentInEurope: values.isEuropeanResident,
                agreedToTerms: values.termsAndConditions,
                referalCode: values.refCode
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${location?.state?.token}`
                }
              }
            )
            .then(function (response) {
              if (values?.refCode) {
                createGoogleAnalyticsForSignup({ signupMethod: SIGNUP_METHODS.REFERAL });
              } else {
                createGoogleAnalyticsForSignup({ signupMethod: SIGNUP_METHODS.ORGANIC });
              }
              console.log(response.data.data, "response.data.data");
              dispatch(loginSuccess(response.data.data));

              navigate("/home", {
                state: { social: response.data.data }
              });
            })
            .catch(function (error) {
              if (error?.response?.data?.data?.message) {
                const message = error?.response?.data?.data?.message;
                dispatch({
                  type: "SHOW_NOTIFICATION",
                  alertSeverity: "error",
                  message: message
                });
                return;
              }

              dispatch({
                type: "SHOW_NOTIFICATION",
                alertSeverity: "error",
                message: error?.message || "Error"
              });
            });
        }
      }}
      className="auth-form"
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          {/* Input Wrap */}
          <Box className="auth-input-wrap">
            <InputLabel
              sx={{ color: theme.palette.mode === "dark" ? "white" : "#404040" }}
              className="auth-label"
              htmlFor="outlined-adornment-name-signup"
            >
              First Name
            </InputLabel>
            <FormControl className="auth-form-control" fullWidth error={Boolean(touched.firstName && errors.firstName)}>
              <TextField
                placeholder="First Name"
                className="auth-input"
                variant="outlined"
                type="text"
                name="firstName"
                required
                value={values.firstName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {touched.firstName && errors.firstName && (
                <FormHelperText error id="standard-weight-helper-text-name-login">
                  {errors.firstName}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          {/* Input Wrap End */}

          {/* Input Wrap */}
          <Box className="auth-input-wrap">
            <InputLabel
              sx={{ color: theme.palette.mode === "dark" ? "white" : "#404040" }}
              className="auth-label"
              htmlFor="outlined-adornment-email-signup"
            >
              Last Name
            </InputLabel>
            <FormControl className="auth-form-control" fullWidth error={Boolean(touched.lastName && errors.lastName)}>
              <TextField
                placeholder="Last Name"
                className="auth-input"
                variant="outlined"
                required
                type="text"
                name="lastName"
                value={values.lastName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {touched.lastName && errors.lastName && (
                <FormHelperText error id="standard-weight-helper-text-name-login">
                  {errors.lastName}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          {/* Input Wrap End */}

          {/* Input Wrap */}
          <Box className="auth-input-wrap">
            <InputLabel
              sx={{ color: theme.palette.mode === "dark" ? "white" : "#404040" }}
              className="auth-label"
              htmlFor="outlined-adornment-email-signup"
            >
              Referral Code (Optional)
            </InputLabel>
            <FormControl className="auth-form-control" fullWidth>
              <TextField
                className="auth-input"
                variant="outlined"
                required
                type="text"
                name="refCode"
                value={values.refCode}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
          {/* Input Wrap End */}

          {/* Input Wrap */}
          <Box className="auth-input-wrap" sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={values.isEuropeanResident}
                  onChange={handleChange}
                  name="isEuropeanResident"
                />
              }
              label={<span>I am a resident of the European Union</span>}
            />
          </Box>
          {/* Input Wrap End */}

          {/* Input Wrap */}
          <Box className="auth-input-wrap" sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.termsAndConditions}
                  onChange={handleChange}
                  name="termsAndConditions"
                  color="primary"
                />
              }
              label={
                <span>
                  By checking this box, you agree to our{" "}
                  <Link href="https://galileoprotocol.io" target="_blank" rel="noopener noreferrer">
                    Terms and Conditions
                  </Link>
                </span>
              }
            />
          </Box>
          {touched.termsAndConditions && errors.termsAndConditions && (
            <FormHelperText sx={{ paddingLeft: "1em" }} error id="standard-weight-helper-text-name-login">
              This is required
            </FormHelperText>
          )}
          {/* Input Wrap End */}

          {/* Input Wrap */}
          <Box className="auth-input-wrap" sx={{ mb: 4 }}>
            <FormControlLabel
              control={
                <Checkbox checked={values.emailOptIn} onChange={handleChange} name="emailOptIn" color="primary" />
              }
              label={
                <span>
                  Select this box to receive updates and marketing. You can change your preferences at any time as per
                  our Privacy Policy.
                </span>
              }
            />
          </Box>
          {/* Input Wrap End */}

          {/* Submit Button */}
          <Box sx={{ mt: 2 }} className="btn-wrap">
            <AnimateButton>
              {/* <Stack sx={{ alignItems: "center" }}>
    <CircularProgress />
  </Stack> */}
              <Button
                className="signbuttonMarket"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
              >
                Sign Up
              </Button>
            </AnimateButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default SocialSignUpForm;
